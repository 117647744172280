<div id="myCarousel" class="carousel slide banner-1" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide" src="assets/images/banner-1a.jpg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          
          <p class="hidden-sm-down"><a class="btn btn-md btn-default" href="/contact" role="button">Contact us today</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="second-slide" src="assets/images/banner-2.gif" alt="Second slide">
      <div class="container">
        <div class="carousel-caption banner1">
          <h2 class="wow fadeInUp">We Care About Your Health</h2>
          <h6>We focus on your long term health and preventative measures.</h6>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="third-slide" src="assets/images/banner-3.gif" alt="Third slide">
      <div class="container">
        <div class="carousel-caption banner1 text-right">
          <h2 class="wow fadeInUp">From the Comfort of Your Home</h2>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> </div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>What we Provide to our Clients</h2>
          <h6>We keep our patients active and pain free while in the comfort and safety of their own home!</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-surgeon"></i></div>
          <div class="text-cont">
            <h6>Surgery Pre-hab</h6>
            <p>Prepare for your surgery for a faster recovery.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-muscle"></i></div>
          <div class="text-cont">
            <h6>Pain Management</h6>
            <p>Pain should not be a normal part of life!</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-medical-sign"></i></div>
          <div class="text-cont">
            <h6>Chronic Conditions</h6>
            <p>We can help you maintain your functional independence.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-medical-sign-alt"></i></div>
          <div class="text-cont">
            <h6>Fall Prevention</h6>
            <p>Falling is not a normal part of the aging process.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-doctor"></i></div>
          <div class="text-cont">
            <h6>Wellness</h6>
            <p>We can help you become healthier versions of yourself!</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-gym"></i></div>
          <div class="text-cont">
            <h6>One Step Further</h6>
            <p>We can continue your therapy care to meet your personal goals!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-lg-offset-right-6">
        <div class="text-left">
          <h2>Why Choose us</h2>
          <p>Traditional therapy clinics require Licensed therapists to see up to 4 patients every hour, resulting in the majority of the treatments time spent with a minimally trained assistant or technician.  Alworth physical therapy provides Mobile concierge therapy services allowing for 100% of your treatment session to be 1 on 1 with your licensed physical therapist allowing for a truly personalized and fluid experience.</p>
          <p>
          Our mobile physical therapy model eliminates many of the most frustrating things about healthcare. We eliminate the wait time and deliver one on one care with a consistent therapist. Individualized patient care allows for patients to recover faster, saving patients both time and money!</p>
          <p>
          By receiving physical therapy in your home, office, or location of your choice, and avoiding busy clinics, may help to reduce your exposure to the novel coronavirus. During this time we are especially sensitive to your concerns and we take appropriate precautions for your safety while continuing to monitor CDC recommendations.
          </p>
        </div>
      </div>
    </div>
    
  </div>
</section>    

<section class="testimonials">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="owl-carousel owl-theme testimonials-cont">
        
          <div class="item">
            <div class="testimonial-cont">
            
              <div class="testimonial-client-img aligncenter"><img src="assets/images/jen.png" class="rounded-circle" alt="t-2"></div>
           
              <blockquote>-I have been practicing Physical Therapy since 2004 after obtaining a Masters in Physical Therapy from the University of the Sciences in Philadelphia.  During my 15+ years in practice I have worked in a variety of settings including outpatient clinics, assisted living facilities, skilled nursing, and home health.  My passion for fitness led me to crossfit where I obtained her Crossfit Level 2 trainer certification and have been training athletes for the past 5 years.  I have obtained my Home Health provider certification,  Functional Movements Screening,  and Functional Movement Taping certifications.  I have a passion for fitness and wellness with the goal to keep patients pain free so that they are able to do the things that are most important to them!
              </blockquote>
              <div class="testimonial-info">
              
              <div class="testimonial-client">
                <h6>Jennifer Alworth </h6>
                <p>Founder and Physical Therapist</p>
                <p align="center">
                <img src="assets/images/mainLogo.png" style="width: 300px;">
                </p>
              </div>
              </div>
            </div>
          </div>
          
          
          
        </div>
      </div>
    </div>
  </div>
</section>