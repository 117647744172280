import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	public success: string;
	constructor(private route: ActivatedRoute) {
	    console.log('Called Constructor');
	    this.route.queryParams.subscribe(params => {
	        this.success = params['s'];
	    });
	}


  ngOnInit(): void {
  	

  }

}
