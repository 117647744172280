<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Portfolio</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Portfolio</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="portfolioFilter text-center"> <a href="#" data-filter="*" class="current">All Categories</a> / <a href="#" data-filter=".people">Layout</a> / <a href="#" data-filter=".places">HTML</a> / <a href="#" data-filter=".food">Wordpress</a> / <a href="#" data-filter=".objects">Responsive</a> / </div>
        <div class="portfolioContainer">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 objects">
              <div class="grid objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/1.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 1</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/1.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/2.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 2</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/2.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 food">
              <div class="grid food">
                <figure class="effect-lily"> <img src="assets/images/portfolio/3.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 3</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/3.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/4.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 4</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/4.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 places objects">
              <div class="grid places objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/5.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 5</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/5.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people food objects">
              <div class="grid people food objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/6.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 6</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/6.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
