<div id="myCarousel" class="carousel slide banner-1" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide" src="assets/images/banner-3.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h5 class="wow fadeInUp">Lorem Ipsum is simply dummy </h5>
          <h2 class="wow fadeInUp">Build a Good Relationshp</h2>
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s,</h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn-default" href="#" role="button">View Details</a> &nbsp; <a class="btn btn-md btn-primary" href="#" role="button">Sign up today</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="second-slide" src="assets/images/banner-2.png" alt="Second slide">
      <div class="container">
        <div class="carousel-caption banner1">
          <h5 class="wow fadeInUp">Lorem Ipsum is simply dummy </h5>
          <h2 class="wow fadeInUp">We are Creative People</h2>
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s,</h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn-default" href="#" role="button">Learn more</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="third-slide" src="assets/images/banner-1.png" alt="Third slide">
      <div class="container">
        <div class="carousel-caption banner1 text-right">
          <h5 class="wow fadeInUp">Lorem Ipsum is simply dummy </h5>
          <h2 class="wow fadeInUp">We are <span>X</span>eos<span>.</span></h2>
          <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s,</h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn-default" href="#" role="button">Browse gallery</a></p>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> </div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="about-2-text">
          <h2>We are Xeos. Consulting Firm</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod temp incidunt ut labore et dolore magna aliqua uat enim ad minim veniama quis nostrud ullamcoab oris nisi ut aliquip ex ea commodo consequat. </p>
          <p>Dolor in reprehenderit in voluptate velit esse cillum dolore fugiat nulla pariatur sed 
            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <p class="hidden-sm-down"><a class="btn btn-md btn-primary" href="#" role="button">Learn more</a></p>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4><span class="numscroller roller-title-number-1 scrollzip isShown" data-min="1" data-max="250" data-delay="5" data-increment="10" data-slno="1">250</span>+</h4>
              <h6>Clients</h6>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4><span class="numscroller roller-title-number-2 scrollzip isShown" data-min="1" data-max="250" data-delay="5" data-increment="10" data-slno="2">250</span>+</h4>
              <h6>Clients</h6>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4><span class="numscroller roller-title-number-3 scrollzip isShown" data-min="1" data-max="250" data-delay="5" data-increment="10" data-slno="3">250</span>+</h4>
              <h6>Clients</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="about-2-img"><img class="img-fluid" src="assets/images/about-2.png" alt="about-2"></div>
      </div>
    </div>
  </div>
</section>
<section class="company-history">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="company-history-txt">
          <h2>Who we are & What we do</h2>
          <p>Of Letraset sheets containing Lorem Ipsum passages, and more recently 
            with desktop publishing software like Aldus PageMaker including 
            versions of Lorem Ipsum. <br/>
            <br/>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Ipsum has been the industry's standard dummy text ever since the 1500s, 
            unknown printer took a galley of type and scrambled it to make a type </p>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="company-history-txt">
          <h2>Our History</h2>
          <p>Of Letraset sheets containing Lorem Ipsum passages, and more recently 
            with desktop publishing software like Aldus PageMaker including 
            versions of Lorem Ipsum.</p>
          <ul>
            <li>- Lorem Ipsum is simply dummy text of the</li>
            <li>- psum has been the industry's </li>
            <li>-  unknown printer took a galley of type </li>
            <li>-  t has survived not only five centuries, but </li>
            <li>-  remaining essentially unchanged. </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding">
  <div class="container">
  	 <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Our Previous work for our Clients</h2>
          <h6>established fact that a reader will be distracted by the readable content</h6>
        </div>
      </div>
    </div>
     <div class="row">
      <div class="col-md-12">
        <div class="portfolioFilter text-center"> 
        	<a href="#" data-filter="*" class="current">All Categories</a> / 
            <a href="#" data-filter=".people">Layout</a> / 
            <a href="#" data-filter=".places">HTML</a> / 
            <a href="#" data-filter=".food">Wordpress</a> / 
            <a href="#" data-filter=".objects">Responsive</a> / 
        </div>
        <div class="portfolioContainer">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 objects">
              <div class="grid objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/1.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 1</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/1.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/2.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 2</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/2.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 food">
              <div class="grid food">
                <figure class="effect-lily"> <img src="assets/images/portfolio/3.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 3</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/3.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people places">
              <div class="grid people places">
                <figure class="effect-lily"> <img src="assets/images/portfolio/4.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 4</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/4.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 places objects">
              <div class="grid places objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/5.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 5</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/5.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 people food objects">
              <div class="grid people food objects">
                <figure class="effect-lily"> <img src="assets/images/portfolio/6.png" class="img-fluid" alt="img01"/>
                  <figcaption>
                    <h2>Image 6</h2>
                    <p>Lily likes to play with crayons and pencils</p>
                    <p><a class="info magnific-popup" href="assets/images/portfolio/6.png"><b>View Large</b></a></p> </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-ash">
  <div class="container">
  	<div class="row">
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/1.png" class="img-fluid" alt="item 1"></div>
      </div>
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/2.png" class="img-fluid" alt="item 1"></div>
      </div>
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/3.png" class="img-fluid" alt="item 1"></div>
      </div>
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/4.png" class="img-fluid" alt="item 1"></div>
      </div>
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/5.png" class="img-fluid" alt="item 1"></div>
      </div>
      <div class="col-lg-2 col-md-3">
      	<div class="clients-cont"><img src="assets/images/logo/6.png" class="img-fluid" alt="item 1"></div>
      </div>
    </div>  
  </div>
</section>  