<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/main_top.jpg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Contact</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Contact</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-left">
          <h4>Submit Your Message</h4>
          <p>We will get back to you as soon as we can!</p>
          <div class="row"  *ngIf="success=='true'">
            <div class="col-xs-12">
              <p class="alert alert-success">
                  <strong>Message Success!</strong> Thank you.  We have received your message.

              </p>
            </div>
          </div>
          <div class="form-cont">
            <form action="process.php" method="post" name="ContactForm" id="ContactForm" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="fname" placeholder="First Name *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="lname" placeholder="Last Name *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="tel" class="form-control" name="phone" placeholder="Phone *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" placeholder="Email *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea rows="5" class="form-control" name="comment" placeholder="Your Message *"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary wow tada">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-right">
          <h4>Contact Info</h4>
          <p>Share Your Contact Info with Others</p>
          <div class="contact-info">
            <div class="row">
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-social-facebook"></i></div>
                  <div class="text-cont">
                    <h6>Facebook:</h6>
                    <p><a href="https://www.facebook.com/AlworthPhysicalTherapy">Facebook Page</a></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-social-instagram"></i></div>
                  <div class="text-cont">
                    <h6>Instagram:</h6>
                    <p><a href="https://www.instagram.com/alworthphysicaltherapy/">Instragram Account</a></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-phone"></i></div>
                  <div class="text-cont">
                    <h6>Phone:</h6>
                    <p>732-924-5858</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-email"></i></div>
                  <div class="text-cont">
                    <h6>Email ID:</h6>
                    <p>jen@alworthpt.com</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>