<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/main_top.jpg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Services</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Services</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Who we treat:</h2>
          <h6>At Alworth Physical Therapy we treat a wide variety of patients, from Millennials to Senior Citizens. Most of our clients fall into one of the following categories.</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-baby-trolley"></i></div>
          <div class="text-cont">
            <h6>Busy Parents</h6>
            <p>With the added stresses of virtual learning and modified school schedules, we come to you, eliminating the need to find childcare, and allowing you to receive the services that you need!
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-clock-time"></i></div>
          <div class="text-cont">
            <h6>Working Professionals</h6>
            <p>With time being a precious commodity, avoiding the waiting room allows you to get the most out of your 60 minute 1 on 1 session.  We can even come to you on your lunch hour! 
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-gym"></i></div>
          <div class="text-cont">
            <h6>Wellness</h6>
            <p>Starting a fitness program can be intimidating, especially for someone with health concerns.  We can get you started safely so you can be confident in the process!
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-first-aid"></i></div>
          <div class="text-cont">
            <h6>Pain Management-</h6>
            <p>Physical therapists provide a variety of manual therapy and physical agent techniques to assist in decreasing patients pain over time.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-surgeon"></i></div>
          <div class="text-cont">
            <h6>Surgery Pre-hab</h6>
            <p>Prior to having a surgical procedure completed doctors frequently suggest seeing a physical therapist to initiate a strengthening and range of motion program.  Research has shown that even one or two visits can help to improve recovery.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-doctor"></i></div>
          <div class="text-cont">
            <h6>Chronic Conditions</h6>
            <p>Do you have a condition that isn’t going to go away, but will require continued management? We can continue to see you and help manage your condition without worrying about insurance concerns. Common conditions that we treat are hypermobility syndromes, Multiple Sclerosis, Fibromyalgia, Chronic Lyme Disease, Parkinsons, and many others. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-medical-sign"></i></div>
          <div class="text-cont">
            <h6>Fall risk patients</h6>
            <p>Falling in not a normal part of the aging process!  Over 30 % of falls result in injuries with close to 10% causing a serious injury!  We help to maintain mobility, strength and balance reactions that can be lost related to the aging process.  Home assessment with safety modifications are also key in falls prevention.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-cycling"></i></div>
          <div class="text-cont">
            <h6>One Step Further</h6>
            <p>Many times patients are discharged from traditional therapy and feel that there is still progress to be made or that their personal goals are not yet met.  We can continue to work with you to meet your goals!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  