<nav class="navbar navbar-expand-md btco-hover-menu navbar-1">
  <div class="container"> <a class="navbar-brand" routerLink="/home1"><img src="assets/images/logo.jpg" class="img-fluid" alt="logo"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        
        <li class="nav-item"> <a class="nav-link" routerLink="/">Home</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/about">About</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/services">Services</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/blog">Social Media</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/contact">Contact</a> </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"> <font color="#E2142D">732-924-5858</font> </li>
      </ul>
      
    </div>
  </div>
</nav>
