<footer class="footer">
	<div class="container">
    
    <div class="row">
    	<div class="col-lg-12">
        	<div class="footer-bottom">
            	<p>Copyright © 2020. All Rights Reserved</p>
            </div>
        </div>
    </div>    
    </div>
</footer>
<div id="qnimate" class="off">
  <div id="search" class="open">
    <button data-widget="remove" id="removeClass" class="close" type="button">×</button>
    <form action="#" method="post" autocomplete="off">
      <input type="text" placeholder="Type search keywords here" value="" name="term" id="term">
      <button class="btn btn-primary btn-xl" type="submit"><span class="glyphicon glyphicon-search"></span> Search</button>
    </form>
  </div>
</div>