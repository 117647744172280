<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/main_top.jpg" alt="About Us Banner">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Alworth PT</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / About</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6"><img class="img-fluid abt-img" src="assets/images/aboutus.gif" alt="about-3"></div>
      <div class="col-lg-6">
        <div class="about-3-text">
          <h4>Why choose mobile physical therapy?</h4>
          <p>Traditional therapy clinics require Licensed therapists to see up to 4 patients every hour, resulting in the majority of the treatments time spent with a minimally trained assistant or technician.  Alworth physical therapy provides Mobile concierge therapy services allowing for 100% of your treatment session to be 1 on 1 with your licensed physical therapist allowing for a truly personalized and fluid experience.</p>
          <p>
          Our mobile physical therapy model eliminates many of the most frustrating things about healthcare. We eliminate the wait time and deliver one on one care with a consistent therapist. Individualized patient care allows for patients to recover faster, saving patients both time and money!</p>
          <p>
          By receiving physical therapy in your home, office, or location of your choice, and avoiding busy clinics, may help to reduce your exposure to the novel coronavirus. During this time we are especially sensitive to your concerns and we take appropriate precautions for your safety while continuing to monitor CDC recommendations.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-accordian">
  <div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div id="accordion">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <a class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          What type of equipment do I need to have?
        </a>
      </h5>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        Nothing!  We will provide all equipment.  All we need is you!

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          What is your service area?
        </a>
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        We service all of Monmouth county.  Services are available to clients in  southern middlesex, and northern ocean counties for a $25 travel charge.

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          What should I expect for my first visit?
        </a>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Your initial experience with Alworth physical therapy will be completely different than any previous therapy visit!  Your visit will take place at the location of your choice,  starting on time, and will be completely 1 on 1 with your physical therapist.  To make the most of your time we ask that your intake paperwork be submitted prior to the appointment so it can be reviewed to make the most of the visit.  The goal of the visit will be to develop your personalized plan of care to meet your individual goals.  At that time we can schedule and discuss follow up appointments as well as preferred contact methods between sessions so that we may check in on your progress!

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Does Alworth Physical Therapy take my insurance?
        </a>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        At Alworth Physical therapy we do things a little differently, we are patient focused not treatment focused. You are our first priority, however, as soon as insurance is involved the focus diverts to the treatments given and not on the quality of your care. With us you receive top notch care that you are in control of in your own environment.​
        <br><br>
        We are an out of network provider, therefore reimbursement will be between you and your insurance company - I will provide a superbill after each visit that you are able to submit to your insurance for reimbursement. How much is reimbursed will vary from company to company. For more information contact your insurance provider directly.


      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Why would I choose an out of network provider?

        </a>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        When asked a majority of physical therapists will say that they went into the field to help people.  Over the years I have found that the goal of my patients and their insurance providers is vastly different.  While a patient goal may be to be able to be to decrease their knee pain and improve strength and stability in order to take their kids on a ski trip, insurance will only allow for the patient to meet the company’s goal of “good enough”  or to “safely transition to the next level of care”.  As we do not participate with insurance companies the goal of the treatment is always the goal of the patient!


      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          How much does each appointment cost?
        </a>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
        Please contact us to discuss our pricing.

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingSeven">
      <h5 class="mb-0">
        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
          Do I need a prescription from my doctor to see a physical therapist?

        </a>
      </h5>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
        No.  New Jersey allows patients for direct access to physical therapy professionals.  Your physical therapy will refer you to your primary care provider for assessment prior to further treatment provided if they believe delivering therapy care is not clinically indicated due to the scope of the symptoms or conditions or if after 30 days of intervention reasonably expected progress has not been made. A collaborative approach is important to us at Alworth Physical Therapy, and therefore will be sharing your plan of care with your primary physician.

      </div>
    </div>
  </div>
</div>
    </div>
  </div>  
  </div>
</section>