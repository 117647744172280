

<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/main_top.jpg" alt="About Us Banner">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Alworth PT</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Social Media</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAlworthPhysicalTherapy%2F&tabs=timeline&width=500&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="1000" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>

      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      	<div class="sidebar">
        	<div class="sidebar-item">
            	<h5>Follow Us</h5>
                <hr/>
                <ul>
                	<li><a href="https://www.facebook.com/AlworthPhysicalTherapy">Facebook <i class="icofont icofont-social-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/alworthphysicaltherapy/">Instagram <i class="icofont icofont-social-instagram"></i></a></li>
                    <li><a href="https://www.google.com/search?q=alworth+physical+therapy">Google Business <i class="icofont icofont-brand-google"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/jennifer-alworth-3b21227b/">Linked In  <i class="icofont icofont-social-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>